<template>
  <div>
    <section class="auth-page reset">
      <Breadcrumbs />
      <div class="container">
        <b-row class>
          <b-col md="10" lg="4">
            <h3 class="semiBold-22 text-left auth-title">
              {{ $t("password_reset") }}
            </h3>
            <p class="regular-12">{{ $t("reset_password_text") }}</p>

            <b-form-group
              class="account-inputs"
              id="email-group"
              :label="$t('form_email')"
              label-for="email"
            >
              <b-form-input
                id="email"
                v-model="email"
                type="text"
                :state="emailState"
                @blur="validateEmail"
                aria-describedby="email-feedback"
                :readonly="readOnlyEmail"
              ></b-form-input>
              <b-form-invalid-feedback id="email-feedback">{{
                emailError
              }}</b-form-invalid-feedback>
            </b-form-group>

            <div
              class="form-group c-input-item error"
              :class="{
                'error-danger': !tokenState && tokenState !== null,
                'error-success': tokenState && tokenState !== null,
              }"
            >
              <input
                id="token"
                v-model="token"
                type="hidden"
                :state="tokenState"
                aria-describedby="token-feedback"
              />
              <small class="form-error" v-if="!tokenState">
                {{ tokenError }}
              </small>
            </div>

            <b-form-group
              class="account-inputs password-input"
              id="password-group"
              :label="$t('form_password')"
              label-for="password"
            >
              <b-form-input
                id="password"
                v-model="password"
                type="password"
                :state="passwordState"
                @blur="validatePassword"
                aria-describedby="password-feedback"
              ></b-form-input>
              <client-only>
                <password-meter :password="password" />
              </client-only>
              <b-form-invalid-feedback id="password-feedback">{{
                passwordError
              }}</b-form-invalid-feedback>
            </b-form-group>

            <b-form-group
              class="account-inputs password-input"
              id="password_confirm-group"
              :label="$t('form_password_confirmation')"
              label-for="password_confirm"
            >
              <b-form-input
                id="password_confirm"
                v-model="password_confirm"
                type="password"
                :state="password_confirmState"
                @blur="validateConfirmationPassword"
                aria-describedby="password_confirm-feedback"
              ></b-form-input>
              <b-form-invalid-feedback id="password_confirm-feedback">{{
                password_confirmError
              }}</b-form-invalid-feedback>
            </b-form-group>

            <div class="btn-wrap">
              <div class="btn-holder text-right">
                <b-button @click="formSubmit" class="c-btn c-btn-primary">
                  {{ $t("reset_my_password") }}
                </b-button>
              </div>
              <div class="go-back-text">
                <p class="go-back">
                  <b-link @click="$router.back()">{{ $t("go_back") }}</b-link>
                </p>
              </div>
            </div>
          </b-col>
        </b-row>
      </div>
    </section>
  </div>
</template>

<script>
import Breadcrumbs from "@/base/core/components/breadcrumb/Breadcrumbs.vue";
import { resetPassword } from "@storefront/core/data-resolver/user";
import ClientOnly from "vue-client-only";
import { isServer } from "@storefront/core/helpers";

export default {
  name: "ResetPassword",
  components: {
    Breadcrumbs,
    ClientOnly,
    passwordMeter: () =>
      !isServer ? import("vue-simple-password-meter") : null,
  },
  computed: {
    readOnlyEmail() {
      return (this.$route.query.email !== undefined &&
        this.$route.query.email.length) > 0
        ? true
        : false;
    },
  },
  mounted() {
    const bcrumb = { current: this.$t("password_reset"), routes: [] };
    this.$store.commit("breadcrumbs/set", bcrumb);
  },
  metaInfo() {
    return {
      meta: [
        { name: "title", content: this.$t("password_reset") },
        { name: "keywords", content: this.$t("password_reset") },
        { name: "description", content: this.$t("password_reset") },
      ],
      title: this.$t("password_reset"),
      link: [{ rel: "canonical", href: "/customer/account/createPassword" }],
    };
  },
  data() {
    return {
      email: this.$route.query.email,
      emailState: null,
      emailError: "",
      description: "",
      password: "",
      passwordState: null,
      passwordError: "",
      password_confirm: "",
      password_confirmState: null,
      password_confirmError: "",
      token: this.$route.query.token,
      tokenState: null,
      tokenError: "",
      emailReg:
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/,
    };
  },
  methods: {
    async formSubmit() {
      if (
        this.validateEmail() &&
        this.validatePassword() &&
        this.validateConfirmationPassword() &&
        this.validateToken()
      ) {
        const retval = await resetPassword(
          this.email,
          this.token,
          this.password
        );
        if (retval == true) {
          this.description = this.$t("password_resetted");
          const msg = {
            type: "success",
            title: "",
            text: this.description,
          };
          this.$store.dispatch("messages/sendMessage", { message: msg });
          this.$router.push({ name: "login" });
        } else {
          this.description = this.$t("password_not_resetted");
        }
      }
    },
    validatePassword() {
      if (this.password.length < 8) {
        this.passwordState = false;
        this.passwordError = this.$t("password_needs");
        return false;
      } else if (this.password.match(/[a-z]/) == null) {
        this.passwordState = false;
        this.passwordError = this.$t("password_needs");
        return false;
      } else if (
        this.password.match(/[!"#$%&'()*+,-.:;<=>?@[\]^_`{|}~]/) == null
      ) {
        this.passwordState = false;
        this.passwordError = this.$t("password_needs");
        return false;
      } else if (this.password.match(/[A-Z]/) == null) {
        this.passwordState = false;
        this.passwordError = this.$t("password_needs");
        return false;
      } else if (this.password.match(/\d/) == null) {
        this.passwordState = false;
        this.passwordError = this.$t("password_needs");
        return false;
      } else {
        this.passwordState = true;
        return true;
      }
    },
    validateConfirmationPassword() {
      if (this.password != this.password_confirm) {
        this.password_confirmState = false;
        this.password_confirmError = this.$t("password_confirm_not_equal");
        return false;
      } else {
        this.password_confirmState = true;
        return true;
      }
    },
    validateToken() {
      if (this.token == "") {
        this.tokenState = false;
        this.tokenError = this.$t("token_not_filled");
        return false;
      } else {
        this.tokenState = true;
        return true;
      }
    },
    validateEmail() {
      if (this.email == "") {
        this.emailState = false;
        this.emailError = this.$t("email_is_required_field");
        return false;
      }
      if (!this.emailReg.test(this.email)) {
        this.emailState = false;
        this.emailError = this.$t("email_is_invalid_field");
        return false;
      }
      this.emailState = true;
      return true;
    },
  },
};
</script>

<style lang="scss" scoped>
.reset {
  padding-bottom: 60px;
  .auth-title {
    padding-bottom: 15px;
    border-bottom: 0;
  }
  .submit-btn {
    margin-top: 23px;
  }
  .go-back {
    margin-top: 25px;
  }
  .form-group {
    margin-bottom: 15px;
  }
  .form-control {
    font-size: 12px;
    &:focus {
      outline: none;
      border-color: $borderColor;
      box-shadow: none;
    }
  }
}
</style>
