<template>
  <div>
    <section class="auth-page forgot">
      <Breadcrumbs />
      <div class="container">
        <div class="row">
          <div class="col col-md-4">
            <h3 class="semiBold-22 text-left auth-title">
              {{ $t("password_forgot") }}
            </h3>
            <p class="regular-12">
              {{ $t("forgot_password_text") }}
            </p>
            <div class="auth-box">
              <div class="auth-form-wrap">
                <div class="auth-form-inner">
                  <div
                    class="form-group c-input-item error"
                    :class="{
                      'error-danger': !emailState && emailState !== null,
                      'error-success': emailState && emailState !== null,
                    }"
                  >
                    <label for="" class="light-12"
                      >{{ $t("form_email")
                      }}<sup class="text-danger">*</sup></label
                    >
                    <input
                      type="email"
                      v-model="email"
                      @blur="validateEmail"
                      :state="emailState"
                      class="c-input-filed light-12"
                      placeholder="E-mailadres"
                    />
                    <small class="form-error" v-if="!emailState">{{
                      emailError
                    }}</small>

                    <!-- <b-form-group
                      :label="$t('form_email') + '*'"
                      label-for="email"
                      :description="description"
                    >
                      <b-form-input
                        id="email"
                        v-model="email"
                        type="text"
                        :state="emailState"
                        aria-describedby="email-feedback"
                      ></b-form-input>
                      <b-form-invalid-feedback id="email-feedback">
                        {{ emailError }}
                      </b-form-invalid-feedback>
                    </b-form-group> -->
                  </div>
                  <!-- <div class="form-group c-input-item">
                    <label for="" class="light-12"
                      >{{ $t("form_email")
                      }}<sup class="text-danger">*</sup></label
                    >
                    <input
                      type="email"
                      v-model="email"
                      :state="emailState"
                      class="c-input-filed light-12"
                      placeholder="E-mailadres"
                    />
                  </div> -->
                  <div class="submit-btn text-right">
                    <button @click="formSubmit" class="c-btn c-btn-primary">
                      {{ $t("reset_my_password") }}
                    </button>
                  </div>
                </div>
              </div>
            </div>

            <div class="go-back-text">
              <p class="go-back">
                <b-link @click="$router.back()">{{ $t("go_back") }}</b-link>
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import Breadcrumbs from "@/base/core/components/breadcrumb/Breadcrumbs.vue";
import { forgotPassword } from "@storefront/core/data-resolver/user";
import config from "@config";
export default {
  name: "ForgotPassword",
  components: {
    Breadcrumbs,
  },
  mounted() {
    const bcrumb = { current: this.$t("password_forgot"), routes: [] };
    this.$store.commit("breadcrumbs/set", bcrumb);
  },
  metaInfo() {
    return {
      meta: [
        { name: "title", content: this.$t("password_forgot") },
        { name: "keywords", content: this.$t("password_forgot") },
        { name: "description", content: this.$t("password_forgot") },
      ],
      title: this.$t("password_forgot"),
      link: [{ rel: "canonical", href: config.app_url + this.$route.fullPath }],
    };
  },
  data() {
    return {
      email: "",
      emailState: null,
      emailError: "",
      description: "",
      emailReg:
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/,
    };
  },
  methods: {
    async formSubmit() {
      if (this.validateEmail()) {
        const retval = await forgotPassword(this.email);
        if (retval == true) {
          this.description = this.$t("reset_password_email_has_been_sent");
          const msg = {
            type: "success",
            title: "",
            text: this.description,
          };
          this.$store.dispatch("messages/sendMessage", { message: msg });
        } else {
          this.description = this.$t(
            "reset_password_email_could_not_been_sent"
          );
        }
      }
    },
    validateEmail() {
      if (this.email == "") {
        this.emailState = false;
        this.emailError = this.$t("email_is_required_field");
        return false;
      }
      if (!this.emailReg.test(this.email)) {
        this.emailState = false;
        this.emailError = this.$t("email_is_invalid_field");
        return false;
      }
      this.emailState = true;
      return true;
    },
  },
};
</script>

<style lang="scss" scoped>
.forgot {
  .auth-title {
    padding-bottom: 15px;
    border-bottom: 0;
  }
  .submit-btn {
    margin-top: 23px;
  }
  .go-back {
    margin-top: 25px;
  }
}
</style>