<template>
   <ForgotPasswordDetail />
</template>

<script>
import ForgotPasswordDetail from "@/base/core/components/authentication/forgot-password/ForgotPasswordDetail.vue"
export default {
  name: "Forgot Password Detail",
  components: {
  ForgotPasswordDetail
  },
  
};
</script>

