<template>
   <ResetPasswordDetail />
</template>

<script>
import ResetPasswordDetail from "@/base/core/components/authentication/reset-password/ResetPasswordDetail.vue"
export default {
  name: "Login",
  components: {
  ResetPasswordDetail
  },
  
};
</script>

